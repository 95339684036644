/* ChatView.css */
.chat-container {
    width: 90%;
    max-width: 500px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .mode-selector {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .mode-selector label {
    font-weight: bold;
  }
  
  .mode-selector select {
    flex: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .chat-box {
    flex: 1;
    height: 300px;
    overflow-y: auto;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
  }
  
  .chat-message {
    padding: 8px 12px;
    margin: 5px 0;
    border-radius: 5px;
    max-width: 80%;
  }
  
  .chat-message.user {
    background-color: #d1e7fd;
    align-self: flex-end;
  }
  
  .chat-message.assistant {
    background-color: #e2e2e2;
    align-self: flex-start;
  }
  
  .chat-message strong {
    display: block;
    margin-bottom: 3px;
  }
  
  .chat-message p {
    margin: 5px 0;
  }
  
  .chat-message h1, h2, h3 {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .chat-message ul {
    margin-left: 20px;
    padding-left: 15px;
  }
  
  .chat-message ul li {
    list-style-type: disc;
  }
  
  .loading {
    font-style: italic;
    color: gray;
  }
  
  .input-box {
    display: flex;
    margin-top: 10px;
  }
  
  .input-box input {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .input-box input:focus {
    border-color: #007bff;
  }